import React from 'react';
import { Form, Col, Table, Tab, Tabs, Spinner} from 'react-bootstrap';
import _ from 'lodash';
import UploadFile from './UploadFile';
import NotificationsTable from "./NotificationsTable";

function TaskSummary(props) {
  const item_attr = {name: "test", email: "email_test", phone: "phone_test"}

  function getCommunicationData(id){
    return props.notification_communication_data[id] || {}
  }

  const assignee_type =  props.assignees_list.length > 1 ? "all" : props.notification_communication_data[((props.assignees_list || [])[0] || {})["value"]] ? "one" : "all"
  const send_notif = props.assignees_list.length > 0 || (props.tag_customer_type === "member" ? props.tag_member_id : props.tag_customer_type === "guest")
  const tag_customer = props.tag_customer_type === "member" ? getCommunicationData(props.tag_member_id) : props.tag_guest
  const assingee_data = assignee_type === "one" ? getCommunicationData(((props.assignees_list || [])[0] || {})["value"]) : getCommunicationData("assignee_notification")

  return ( <div className="col">
  <Tabs
      defaultActiveKey={"attachments"}
      transition={false}
      id="noanim-tab-example"
      className="mb-3"
    >
    <Tab eventKey="attachments" title="Files">
      <Table responsive>
        <thead>
        <tr>
          <th>File</th>
          <th>Date</th>
          <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className={props.isRestricted ? "restricted-container" : ""}>
          {(props.files.length > 0) && 
            props.files.map((file) => (
              <tr key={file.id} className={props.state["remove_file_with_id_"+file.id] ? "d-none" : ""}>
                <td> 
                  <a className="link" target= '_blank' download={file.name} href={file.url}>{file.name}</a>
                </td>
                <td>{file.created_at}</td>
                <td>
                <Form.Control as="textarea" rows={3} 
                  name={"file_description_for_"+file.id}
                  defaultValue={file.description || ""}
                  onChange={(e) => props.handleInputChange(e.target.name, e.target.value)}/>
                </td>
                <td>
                  {<a className="link color_red" name={"remove_file_with_id_"+file.id} onClick={(e) => props.handleInputChange(e.target.name, true)} assigned="remove_file">Remove</a>}
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
      <div className="text-center w-100">
        <Spinner className="d-none" animation="border" variant="warning" id="upload_spinner"/>
      </div>
      {!props.isRestricted && <UploadFile uploadFile={props.uploadFile} customer_id={props.customer_id} item_id={props.state.id || "new"}/>}
    </Tab>
    {props.btn_name === "Create" && send_notif && <Tab eventKey="notif" title="Notifications">
      {props.assignees_list.length > 0 && <NotificationsTable assingee_data={assingee_data}
                                                              assignee_type={assignee_type}
                                                              tag_customer={null}/>}
      {props.tag_customer_type && <NotificationsTable assingee_data={null}
                                                      assignee_type={"solo"}
                                                      tag_customer={tag_customer}/>}
      <div className="guest_notifications_link p-2">
        Notification will be sent based on system wide notification preferences defined
        <a className="link ms-1 me-1" href={"/customers/" +props.customer_id+ "/email_templates"} target="_blank">
          here
        </a>
        for email and
        <a className="link ms-1 me-1" href={"/customers/" +props.customer_id+ "/text_message_templates"} target="_blank">
          here
        </a>
        for text.
      </div>
    </Tab>}
    {props.btn_name != "Create" && <Tab eventKey="activity_log" title="Activity Log">
      <Col className="h-100 table-responsive activity_log_content tableFixHead">
        <Table responsive>
          <thead>
          <tr>
            <th>Date / Time</th>
            <th>Description</th>
          </tr>
          </thead>
          <tbody>
          {(props.state.activity_logs.length > 0) &&
              props.state.activity_logs.map((log) => (
                <tr key={log["id"]}>
                  <td> 
                    {log["date_time"]}
                  </td>
                  <td>
                    {log["message"]}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Col>
    </Tab>}
  </Tabs>
    <Form.Group className="mb-3" controlId="formInternalNotes">
      <Form.Label>Internal Notes</Form.Label>
      <Form.Control as="textarea" rows={3}
                    className={props.isRestricted ? "restricted-container" : ""}
                    name='internal_notes'
                    value={props.state.internal_notes || ""}
                    onChange={(e) => props.handleInputChange("internal_notes", e.target.value)}/>
    </Form.Group>
  </div>)
} 
export default TaskSummary