import React, { useId } from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter } from './Modal';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { Row, Form, Col} from 'react-bootstrap';
import moment from 'moment-timezone'
import { useState } from 'react';
import TaskSummary from "./TaskSummary";
import {toast} from "react-toastify";
import DatePickerWithTimezone from "./DatePickerWithTimezone";
import _ from "lodash";

function TaskModal(props) {

  const initialState = {
    ...props.item,
  }

  const [state, setState] = useState(initialState);

  const { id, name, due_date, tag_member_id, description, recurring, recurring_cadence, recurring_list_references, is_important,
    recurring_end_date,
    assignees_list, due_time, tags_ids, tag_customer_type,
    tag_guest_first_name, tag_guest_last_name, tag_guest_email, tag_guest_phone } = state;
  const { files } = props.item

  const { modal_open, closeModal, members_list, users_list, title, btn_name,
    updateStockOrder, customer_id, similar_task, cadence_options,
    assign_to_options, due_date_options, default_due_time, tags_options, createTask, updateTask, deleteTask,
    timezone, dateTimeFormat, isRestricted } = props;

  const handleInputChange = (name, value) => {
    if(name === "recurring_cadence") {
      const recurring_val = !!value
      let recurring_cadence_details = []

      if(value === "monthly")
        recurring_cadence_details = [moment().format('D')];
      if(value === "yearly")
        recurring_cadence_details = [moment().format('D/M')];

      setState({...state, recurring_cadence: value, recurring: recurring_val, recurring_list_references: recurring_cadence_details});
    }
    else
      setState({...state, [name]: value });
  }

  const handleRecurringDetails = (value, type) => {
    let new_recurring_list_references = recurring_list_references
    if(recurring_cadence === "weekly")
      if(type === "push") {
        new_recurring_list_references.push(value);
        setState({...state, recurring_list_references: new_recurring_list_references});
      }
      else
      {
        new_recurring_list_references = new_recurring_list_references.filter(function (option) {
          return option !== value;
        })

        setState({...state, recurring_list_references: new_recurring_list_references});
      }
    else
      if(recurring_cadence === "monthly")
        setState({...state, recurring_list_references: [value ? moment(value).format('D') : moment().format('D')] });
      else
        if(recurring_cadence === "yearly")
          setState({...state, recurring_list_references: [value ? moment(value).format('D/M') : moment().format('D/M')] });
        else
          setState({...state, recurring_list_references: [value] });
  }

  const handleClose = () => {
    closeModal()
  }

  const handleSubmit = (createSimilar) => {
    let params = {...state}

    if(!params["name"]) {
      window.gems.toast_message.init("Task Name cannot be empty.", "warning")
      return false;
    }

    if(params["recurring"])
      if(params["recurring_cadence"] === "weekly" && params["recurring_list_references"].length <= 0) {
        window.gems.toast_message.init("Please select at least one day of the week.", "warning")
        return false;
      }

    params["assignees_list"] = JSON.stringify(assignees_list)
    params["tags_ids"] = JSON.stringify(tags_ids)
    params["recurring_list_references"] = JSON.stringify(recurring_list_references)

    if(id) {
      params["files"] = JSON.stringify([])
      updateTask(params)
    }
    else
    {
      props.item.files.map((element) => {
        params["files"].push(element.id)
      })
      params["files"] = JSON.stringify(params["files"])

      createTask(params, createSimilar)
    }
  }

  const handleDelete = () => {
    deleteTask(id)
  }

  const select_styles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: 0,
      boxShadow: "none",
      backgroundColor: "white",
      color: "#212529",
      width: "100%",
    }),
      option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected || state.isFocused ? '#FDEDE9' : 'inherit',
      color: state.isSelected ? '#212529' : 'inherit',
    }),
      borderRadius: 0,
      groupHeading: (base) => ({
      ...base,
      marginRight: '10px',
      marginLeft: '10px',
      border: '1px solid #F7F7F7',
      borderWidth: 'thin'
    })
  }
  // if (similar_stock_order && !is_similar_stock_order) {
  //   setState(update(state, {
  //     is_similar_stock_order: { $set: true },
  //     vendor_id: { $set: similar_stock_order.vendor_id },
  //     order_number: { $set: similar_stock_order.order_number },
  //     order_nickname: { $set: similar_stock_order.order_nickname },
  //     order_description: { $set: similar_stock_order.order_description },
  //     color: { $set: similar_stock_order.color },
  //     detailed_entry: { $set: similar_stock_order.detailed_entry },
  //     stock_order_categories: { $set: similar_stock_order.stock_order_categories },
  //   }))
  // }

  const date_format = dateTimeFormat.replaceAll('m', 'M').split(' ')[0]
  const time_format = dateTimeFormat.replaceAll('M', 'm').split(' ')[1] + (props.dateTimeFormat.split(' ')[2] ? " a" : "")
  const tag_customer_type_options = [{label: "No tag customer", value: null } ,{label: "Member", value: "member"}, {label: "Guest", value: "guest"}];
  const week_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const due_date_select_value = (_.find(due_date_options, (d) => (moment(d["real_value"]).format(date_format.toUpperCase()) === moment(due_date).tz(timezone).format(date_format.toUpperCase()))) || {value: isNaN(new Date(due_date)) ? due_date || "today" : "custom"})["value"]
  const tag_guest_data = {name: tag_guest_first_name + " " + tag_guest_last_name, email: tag_guest_email, phone: tag_guest_phone,
                              email_notif: props.notification_communication_data["tag_customer_notification"]["email"],
                              sms_notif: props.notification_communication_data["tag_customer_notification"]["email"]}

  return (
    <div className="modal_task_container">
      {/* TO DO: modal is called even if no data is initialized and is set to isOpen false but this component is rendered anyhow */}
      <Modal isOpen={modal_open}>
        <ModalHeader >
          <div className="row">
            <div className="col-7 order-1">
              <h4 className="modal-title form_new_edit no-margin">{title}</h4>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Form id="TaskForm">
            <div className="row">
              <div className="col pt-3">
                <Form.Group as={Row} className="mb-3" controlId="taskName">
                  <Form.Label column sm="3">
                    Task Name
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                    <Form.Control type="text"
                                  name='name'
                                  value={name}
                                  onChange={(e) => handleInputChange("name", e.target.value)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="taskAssignTo">
                  <Form.Label column sm="3">
                    Assign To
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                    <Select id="assignee"
                            name='assignee'
                            className="basic-multi-select"
                            isMulti
                            value={assignees_list}
                            options={assign_to_options}
                            onChange={(e) => handleInputChange("assignees_list", e)}
                            styles={select_styles}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="dueDate">
                  <Form.Label column sm="3">
                    <div className="row">
                      <div className="col-12">
                        Due Date
                      </div>
                    </div>
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container d-flex pe-0" : "d-flex pe-0"}>
                    <div className="row d-flex w-100">
                      <div className="col">
                        <Select id="due_date"
                                name='due_date'
                                value={due_date_options.filter(function (option) {
                                  return option.value === (isNaN(new Date(due_date_select_value)) ? due_date_select_value : "custom");
                                })}
                                options={due_date_options}
                                onChange={(e) => handleInputChange("due_date", e.value)}
                                styles={select_styles}
                        />
                      </div>
                      {(due_date_select_value === "custom" || !["today", "tomorrow", "next_week"].includes(due_date_select_value)) &&
                        <div className="col">
                          <DatePickerWithTimezone
                            selected={isNaN(new Date(due_date)) ?
                                        due_date == "today" ?
                                          moment.tz(timezone).format()
                                          : moment().tz(timezone).hour(parseInt(default_due_time["h"])).minute(parseInt(default_due_time["m"])).format()
                                        : due_date}
                            onChange={(date) => handleInputChange("due_date", date)}
                            dateFormat={date_format}
                            timezone={timezone}
                            className="form-control"
                          />
                        </div>
                      }
                      <div className="col pe-0">
                        <DatePickerWithTimezone
                          selected={isNaN(new Date(due_time)) ?
                            isNaN(new Date(due_date)) ?
                              due_date == "today" ?
                                moment.tz(timezone).format()
                                : moment().tz(timezone).hour(parseInt(default_due_time["h"])).minute(parseInt(default_due_time["m"])).format()
                              : due_date
                            : due_time}
                          onChange={(date) => handleInputChange("due_time", date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          timezone={timezone}
                          dateFormat={time_format}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="taskDescription">
                  <Form.Label column sm="3">
                    Task Description
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                    <Form.Control as="textarea" rows={3}
                                  name='des'
                                  value={description}
                                  onChange={(e) => handleInputChange("description", e.target.value)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="taskTagCustomerType">
                  <Form.Label column sm="3">
                    Tag Customer Type
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                    <Select id="tag_customer_type"
                            name='tag_customer_type'
                            value={tag_customer_type_options.filter(function (option) {
                              return option.value === tag_customer_type;
                            })}
                            options={tag_customer_type_options}
                            placeholder="Please select customer type (Optional)"
                            onChange={(e) => handleInputChange("tag_customer_type", e.value)}
                            styles={select_styles}
                    />
                  </Col>
                </Form.Group>
                {tag_customer_type === "member" && <Form.Group as={Row} className="mb-3" controlId="taskTagMember">
                  <Form.Label column sm="3">
                    Tag Customer
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                    <Select id="tag_member_id"
                            name='tag_member_id'
                            value={members_list.filter(function (option) {
                              return option.value === tag_member_id;
                            })}
                            options={members_list}
                            placeholder="Please select member (Optional)"
                            onChange={(e) => handleInputChange("tag_member_id", e.value)}
                            styles={select_styles}
                    />
                  </Col>
                </Form.Group>}
                {tag_customer_type === "guest" &&
                  <>
                    <Form.Group as={Row} className="mb-3" controlId="taskTagGuestFName">
                      <Form.Label column sm="3">
                        Tag Guest First Name
                      </Form.Label>
                      <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                        <Form.Control type="text"
                                      name='tag_guest_first_name'
                                      value={tag_guest_first_name}
                                      onChange={(e) => handleInputChange("tag_guest_first_name", e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="taskTagGuestLName">
                      <Form.Label column sm="3">
                        Tag Guest Last Name
                      </Form.Label>
                      <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                        <Form.Control type="text"
                                      name='tag_guest_last_name'
                                      value={tag_guest_last_name}
                                      onChange={(e) => handleInputChange("tag_guest_last_name", e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="taskTagGuestEmail">
                      <Form.Label column sm="3">
                        Tag Guest Email
                      </Form.Label>
                      <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                        <Form.Control type="text"
                                      name='tag_guest_email'
                                      value={tag_guest_email}
                                      onChange={(e) => handleInputChange("tag_guest_email", e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="taskTagGuestPhone">
                      <Form.Label column sm="3">
                        Tag Guest Phone
                      </Form.Label>
                      <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                        <Form.Control type="text"
                                      name='tag_guest_phone'
                                      value={tag_guest_phone}
                                      onChange={(e) => handleInputChange("tag_guest_phone", e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </>
                }
                <Form.Group as={Row} className="mb-3" controlId="taskTag">
                  <Form.Label column sm="3">
                    Task Tags
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                    <Select id="tags_ids"
                            name='tags_ids'
                            className="basic-multi-select"
                            isMulti
                            value={tags_ids}
                            options={tags_options}
                            onChange={(e) => handleInputChange("tags_ids", e)}
                            styles={select_styles}
                    />
                  </Col>
                  <Form.Label column sm="3">
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                    <a className="link ms-1 me-1" href={"/customers/" +customer_id+ "/shop_task/task_settings"} target="_blank">
                      Manage Tags
                    </a>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="taskImportant">
                  <Form.Label column sm="3">
                    Important
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                    <Form.Check
                      name="is_important"
                      value={is_important || false}
                      defaultChecked={is_important || false}
                      type='checkbox'
                      id="is_important"
                      label=""
                      className="col p-0 d-flex justify-content-center align-items-center"
                      onChange={(e) => handleInputChange("is_important", e.target.checked)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="taskRecurring">
                  <Form.Label column sm="3">
                    Recurring
                  </Form.Label>
                  <div className={`col-9 text-center recurring_container ${recurring ? "active" : ""} ${isRestricted ? "restricted-container" : ""}`}>
                    <div className="d-flex flex-row w-100">
                      <Select id="recurring_cadence"
                              menuPlacement="top"
                              name='recurring_cadence'
                              value={cadence_options.filter(function (option) {
                                return option.value === recurring_cadence;
                              })}
                              options={cadence_options}
                              onChange={(e) => handleInputChange("recurring_cadence", e.value)}
                              styles={select_styles}
                      />
                    </div>
                    {recurring && <>
                      {recurring_cadence === "weekly" && <div className="row gap-5px">
                        {week_days.map((day, index) => (
                          <div className={`col week_day_item ${recurring_list_references.includes(index) ? 'active' : ''}`} key={`${day}_${index}`}>
                            <span
                              className={'mb-1 p-1 item-box cursor_pointer pick_up_dates'}
                              value={index}
                              onClick={(e) => handleRecurringDetails(index, recurring_list_references.includes(index) ? "pull" : "push")}>
                              {day}
                            </span>
                          </div>
                        ))}
                      </div>}
                      {recurring_cadence == "monthly" &&
                        <>
                          <div className="row">
                            Pick a date
                          </div>
                          <div className="row">
                          <DatePicker className="form-control"
                          name='recurring_end_date'
                          id='recurring_end_date'
                          popperPlacement="top-start"
                          onChange={(e) => handleRecurringDetails(e, "push")}
                          selected={recurring_list_references ? Date.parse(moment().set('date', recurring_list_references[0].split('/')[0])) : Date.parse(moment())}
                          value={recurring_list_references ? Date.parse(moment().set('date', recurring_list_references[0].split('/')[0])) : Date.parse(moment())}
                          dateFormat={date_format}
                          autoComplete="off"/>
                          </div>
                      </>
                    }
                    {recurring_cadence == "yearly" &&
                      <>
                        <div className="row">
                          Pick a date
                        </div>
                        <div className="row">
                          <DatePicker className="form-control"
                                      name='recurring_end_date'
                                      id='recurring_end_date'
                                      popperPlacement="top-start"
                                      onChange={(e) => handleRecurringDetails(e, "push")}
                                      selected={recurring_list_references ? Date.parse(moment(recurring_list_references[0], "D/M")) : Date.parse(moment())}
                                      value={recurring_list_references ? Date.parse(moment(recurring_list_references[0], "D/M")) : Date.parse(moment())}
                                      dateFormat={date_format}
                                      autoComplete="off"/>
                        </div>
                      </>
                    }
                    <div className="row">
                      End On
                    </div>
                    <div className="row">
                      <DatePicker className="form-control"
                                  name='recurring_end_date'
                                  id='recurring_end_date'
                                  popperPlacement="top-start"
                                  // showTimeSelect={true}
                                  // timeIntervals={10}
                                  placeholderText="Never"
                                  onChange={(e) => handleInputChange("recurring_end_date", e)}
                                  selected={Date.parse(moment(recurring_end_date))}
                                  value={Date.parse(moment(recurring_end_date))}
                                  // timeFormat="p"
                                  dateFormat={date_format}
                                  autoComplete="off"/>
                    </div>
                    </>}
                  </div>
                </Form.Group>
              </div>
              <div className="col pt-3">
                <TaskSummary files={files}
                             handleInputChange={handleInputChange}
                             uploadFile={props.uploadFile}
                             customer_id={customer_id}
                             tag_customer_type={tag_customer_type}
                             tag_member_id={tag_member_id}
                             tag_guest={tag_guest_data}
                             assignees_list={assignees_list}
                             notification_communication_data={props.notification_communication_data}
                             state={state}
                             isRestricted={isRestricted}
                             btn_name={props.btn_name || null}
                />
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            className="float-start btn gems_custom_button"
            aria-label="Close"
            onClick={handleClose}
          >
            Close
          </button>
          {/*{id &&*/}
          {/*  <button*/}
          {/*  className="float-start btn gems_custom_button gems_red_button"*/}
          {/*  aria-label="Close"*/}
          {/*  onClick={handleDelete}*/}
          {/*>*/}
          {/*  Delete*/}
          {/*</button>}*/}
          {!isRestricted && <>
            <button
              className={`btn gems_custom_button gems_orange_button float-end submit_task_more_options`}
              onClick={(event) => handleSubmit(false)}
            >
              Save Task
            </button>
            {btn_name == "Create" && <button
              className="btn gems_custom_button gems_orange_button float-end"
              onClick={(event) => handleSubmit(true)}
            >
              Save and Add Similar Task
            </button>
          }
          </>}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default TaskModal