import React, { Component } from 'react'

import TaskDashboardBodyContainer from '../containers/TaskDashboardBodyContainer'
import TaskDashboardFilterContainer from '../containers/TaskDashboardFilterContainer'
import TaskDashboardHeader from "./TaskDashboardHeader";
class TaskDashboard extends Component {
  constructor(props){
    super(props)
  }
  
  componentDidMount(){
    this.props.fetchTasksData()
  }

  render() {
    return (
        <div className="col-12 h-100 d-flex flex-column">
          <TaskDashboardHeader
            add_dummy_url={this.props.add_dummy_url}
            delete_dummy_url={this.props.delete_dummy_url}
          />
          <div className="task-dashboard-body row">
            <div className="row task-dashboard-container gx-0">
              <TaskDashboardFilterContainer/>
              <TaskDashboardBodyContainer />
            </div>
          </div>
        </div>
    )
  }
}


export default TaskDashboard
