import { showErrorNotification, callAPI } from 'shared/helpers'
import {toast} from "react-toastify";

export const ActionTypes = {
  FETCH_TASKS_DATA_REQUEST: 'FETCH_TASKS_DATA_REQUEST',
  CREATE_TASK: 'CREATE_TASK',
  NEW_TASK: 'NEW_TASK',
  EDIT_TASK: 'EDIT_TASK',
  DELETE_TASK: 'DELETE_TASK',
  UPDATE_TASK: 'UPDATE_TASK',
  SET_IMPORTANT: 'SET_IMPORTANT',
  COMPLETE_TASK: 'COMPLETE_TASK',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  OPEN_ASSIGN_MODAL: 'OPEN_ASSIGN_MODAL',
  CLOSE_ASSIGN_MODAL: 'CLOSE_ASSIGN_MODAL',
  SWITCH_VIEW: 'SWITCH_VIEW',
  APPLY_FILTERS: 'APPLY_FILTERS',
  HISTORY_SEARCH: 'HISTORY_SEARCH',
  APPLY_FILES_TO_TASK: 'APPLY_FILES_TO_TASK',
}

export const fetchTasksData = () => (dispatch, getState) => {
  const state = getState()

  const dataURL =  "/api/web/customers/"+state.task.customer_id+"/tasks"

  callAPI(dataURL, 'GET', {}, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.FETCH_TASKS_DATA_REQUEST,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const uploadFile = data => (dispatch, getState) => {
  const state = getState()

  const dataURL = "/api/web/customers/"+state.task.customer_id+"/tasks/upload_files"
  if (data) {
    document.getElementById("upload_spinner").classList.remove("d-none");
    return new Promise((resolve, reject) => {
      fetch(
        dataURL,
        {
          method: 'POST',
          body: data,
        }
      )
        .then(response => Promise.all([response, response.json()]))
        .then(([response, json]) => {
          if (response.status === 200 && _.isEmpty(json.errorMessage)) {
            resolve(json)
          } else {
            reject(json.errorMessage, json)
          }
        })
        .catch(() => {
          reject()
        })
    }).then(response => {
      dispatch({
        type: ActionTypes.APPLY_FILES_TO_TASK,
        data: response,
      })
      document.getElementById("upload_spinner").classList.add("d-none");
    })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const createTask = (data, create_similar) => (dispatch, getState) => {
  const state = getState()
  const dataURL = "/api/web/customers/"+state.task.customer_id+"/tasks"
  if (data) {
    data.customer_id = state.task.customer_id
    data.create_similar = create_similar

    callAPI(dataURL, 'POST', data, new Headers({ 'Accept': 'application/json', 'Content-type': 'application/json' }))
      .then(response => {
        // window.location.reload();
        dispatch({
          type: ActionTypes.CREATE_TASK,
          data: response,
        })

        window.gems.toast_message.init("Successfully created!", "success")
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const newTask = (data) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.NEW_TASK,
    data: data,
  })
  // const state = getState()
  //
  // const dataURL = "/api/web/customers/"+state.task.customer_id+"/tasks/new"
  // const params = { similar_task: similarTask }
  //
  // callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
  //   .then(response => {
  //
  //   })
  //   .catch(() => {
  //     showErrorNotification('Error while fetching data!')
  //   })
}

export const editTask = id => (dispatch, getState) => {
  const state = getState()
  if (id) {
    const dataURL = "/api/web/customers/"+state.task.customer_id+"/tasks/" + id + "/edit"
    const params = { customer_id: state.task.customer_id, id: id }

    callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        window.history.pushState(window.location.href, 'Title', window.location.href.split('?')[0]);
        dispatch({
          type: ActionTypes.EDIT_TASK,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const setImportant = data => (dispatch, getState) => {
  const state = getState()
  if (data.id) {
    data.customer_id = state.task.customer_id
    const dataURL = "/api/web/customers/"+data.customer_id+"/tasks/" + data.id + "/set_important"
    callAPI(dataURL, 'POST', data, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.UPDATE_TASK,
          data: response,
        })

        window.gems.toast_message.init("Successfully updated!", "success")
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const setCompleted = data => (dispatch, getState) => {
  const state = getState()
  if (data.id) {
    data.customer_id = state.task.customer_id

    const dataURL = "/api/web/customers/"+data.customer_id+"/tasks/" + data.id + "/set_completed"
    callAPI(dataURL, 'POST', data, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.COMPLETE_TASK,
          data: response,
        })
        window.gems.toast_message.init("Successfully updated!", "success")
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const updateTask = data => (dispatch, getState) => {
  const state = getState()

  if (data.id) {
    data.customer_id = state.task.customer_id
    const dataURL = "/api/web/customers/"+data.customer_id+"/tasks/" + data.id
    callAPI(dataURL, 'PATCH', data, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.UPDATE_TASK,
          data: response,
        })

        window.gems.toast_message.init("Successfully updated!", "success")
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const deleteTask = id => (dispatch, getState) => {
  const state = getState()
  if (id) {
    const dataURL = "/api/web/customers/"+state.task.customer_id+"/tasks/" + id
    const params = { customer_id: state.task.customer_id, id: id }

    callAPI(dataURL, 'DELETE', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.DELETE_TASK,
          data: response,
        })
        window.gems.toast_message.init("Successfully deleted!", "success")
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const historySearch = data => (dispatch, getState) => {
  const state = getState()

  if(data.length > 2) {
    const dataURL = "/api/web/customers/" + state.task.customer_id + "/tasks/history_search?filter=" + data

    callAPI(dataURL, 'POST', data, new Headers({'Accept': 'application/json'}))
      .then(response => {
        dispatch({
          type: ActionTypes.HISTORY_SEARCH,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
  else
    dispatch({
      type: ActionTypes.HISTORY_SEARCH,
      data: {},
    })
}

export const openModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_MODAL,
    data: { modal_open: true },
  })
}

export const closeModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_MODAL
  })
}

export const closeAssignModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_ASSIGN_MODAL
  })
}

export const openAssignModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_ASSIGN_MODAL,
  })
}

export const applyFilters = data => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.APPLY_FILTERS,
    data:  {value: data["value"], name: data["name"]},
  })
}
