import { connect } from 'react-redux'
import TaskDashboard from '../components/TaskDashboard'
import { fetchTasksData } from '../actions/task_actions'

const mapStateToProps = (state) => ({
  delete_dummy_url: state.task.delete_dummy_url,
  add_dummy_url: state.task.add_dummy_url
})

const mapDispatchToProps = (dispatch) => ({
  fetchTasksData: () => dispatch(fetchTasksData())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskDashboard)
