import React from 'react'
import PropTypes from 'prop-types'

import rootReducer from '../reducers/task_reducers'

import configureStore from 'shared/configure_store'
import { GlobalProvider } from 'shared/global_context'

import TasksPageContainer from './TasksPageContainer'

const TasksRoot = ({customer_id, date_time_info, user_id, is_restricted, task_id, add_dummy_url, delete_dummy_url}) => {
  const state = {
    task: {
      customer_id: customer_id,
      date_time_info: date_time_info,
      user_id: user_id,
      condensed_tile_layout: false,
      isRestricted: is_restricted,
      task_id: task_id,
      add_dummy_url: add_dummy_url,
      delete_dummy_url: delete_dummy_url,
    },
  }
  
  const store = configureStore(rootReducer, state)
  return (
    <GlobalProvider store={ store }>
      <TasksPageContainer/>
    </GlobalProvider>
  )
}

TasksRoot.propTypes = {
  customer_id: PropTypes.number.isRequired,
}

export default TasksRoot
