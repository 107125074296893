import React from 'react'
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {PiCircleLight, PiRepeatFill, PiCircleFill} from "react-icons/pi";
import { BsStar, BsStarFill } from "react-icons/bs";
import { VscEdit } from "react-icons/vsc";
import { GoTrash } from "react-icons/go";
import { TbRestore } from "react-icons/tb";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { MdPageview } from "react-icons/md";
function QuickTask(props) {
  const {id, name, due_date, completed_at_date, color, assigned_to, is_important, recurring,
          alerts, accepted_users, cadence, email_read_users, tags,
          checkbox_color, setImportant, isCompleted, setCompleted, isRestricted, userAssigned, taskId} = props

  const handleEdit = (event) => {
    if(id)
      props.editTask(id)
  }
  const handleDelete = () => {
    if(id)
      if(window.confirm("Are you sure you want to delete this task?"))
        props.deleteTask(id)
  }
  const handleImportant = () => {
    setImportant({id: id, is_important: !is_important})
  }
  const handleComplete = () => {
    setCompleted({id: id})
  }

  if (taskId && taskId === id) {
    handleEdit(taskId)
  }

  return (
    <div className="row quick-task-list-item align-vertical-middle flex-column w-100" style={{backgroundColor: color}}>
      <div className="w-100 d-flex align-vertical-middle justify-content-start">
        <div className="quick-task-list-item-first-container">
          <div className="quick-task-list-item-name-container">
            {
              isCompleted ?
                <PiCircleFill onClick={(e) => (!isRestricted || userAssigned) ? handleComplete() : {}}
                              className={`${((!isRestricted || userAssigned) ? '' : 'restricted-container')} fs-4 align-vertical-middle cursor_pointer complete_checkbox`}
                              style={{color: checkbox_color}}/>
                :
                <PiCircleLight onClick={(e) => (!isRestricted || userAssigned) ? handleComplete() : {}}
                               className={`${((!isRestricted || userAssigned) ? '' : 'restricted-container')} fs-4 align-vertical-middle cursor_pointer complete_checkbox`}
                               style={{color: checkbox_color}}/>
            }
            <div className="align-vertical-middle justify-content-start">
              <b className="m-1 fs-6">{name}</b>
              {recurring &&
                <OverlayTrigger
                  key="recurring_cadence"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-recuring_cadence`}>
                      {cadence}
                    </Tooltip>
                  }
                >
                  <div className="h-100 fs-5 recurring_cadence_icon">
                    <PiRepeatFill/>
                  </div>
                </OverlayTrigger>
              }
            </div>
          </div>
          <div className="align-vertical-middle justify-content-start quick-task-list-item-date-container">
            <b>{isCompleted ? completed_at_date["date"] : due_date["date"]}</b>
            <OverlayTrigger
              key="due_time"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-due_time`}>
                  {isCompleted ? completed_at_date["time"] : due_date["time"]}
                </Tooltip>
              }
            >
              <i className=" align-middle gga-info-circle tooltip_info"/>
            </OverlayTrigger>
          </div>
          <div className="align-vertical-middle justify-content-start quick-task-list-item-assignee-container">
            <b className="align-vertical-middle">
              {(assigned_to.split(',').length > 3) ?
                <>
                  <span>Multiple</span>
                  <OverlayTrigger
                    key="assigned_to_multiple"
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-assigned_to_multiple`}>
                        {assigned_to}
                      </Tooltip>
                    }
                  >
                    <i className=" align-middle gga-info-circle tooltip_info"/>
                  </OverlayTrigger>
                </>
                :
                assigned_to}
            </b>
          </div>
        </div>
        <div className="quick-task-list-item-last-container">
          <div className={"align-vertical-middle justify-content-end quick-task-list-item-tags-alerts-container"}>
            {email_read_users &&
              <div className={"align-vertical-middle task-alert"}>
                <span className="email_read basic_label_container">Email Read</span>
                <OverlayTrigger
                  key="email_read_users"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-email_read_users`}>
                    {email_read_users}
                    </Tooltip>
                  }
                >
                  <i className="align-vertical-middle gga-info-circle tooltip_info"/>
                </OverlayTrigger>
              </div>
            }

            {accepted_users &&
              <div className={"align-vertical-middle task-alert"}>
                <span className="accepted_users basic_label_container">Accepted</span>
                <OverlayTrigger
                  key="assigned_users"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-assigned_users`}>
                      {accepted_users}
                    </Tooltip>
                  }
                >
                  <i className="align-vertical-middle gga-info-circle tooltip_info"/>
                </OverlayTrigger>
              </div>
            }
            {tags &&
              (tags["name"] ?
              <div className={"align-vertical-middle task-tag"}>
                  <span className="accepted_users basic_label_container" style={{backgroundColor: tags["color"]}}>
                    {tags["name"]}</span>
              </div>

              :

              <>
                <div className={"align-vertical-middle task-tag"}>
                  <span className="multiple_tags basic_label_container">
                    Multiple Tags</span>
                </div>
                <OverlayTrigger
                  key="assigned_to_multiple"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-multiple-tags`}>
                      {tags}
                    </Tooltip>
                  }
                >
                  <i className="align-vertical-middle gga-info-circle tooltip_info"/>
                </OverlayTrigger>
              </>)}
          </div>
          {is_important ?
            <BsStarFill className={((!isRestricted || userAssigned) ? "" : "restricted-container ") + "align-vertical-middle fs-4 cursor_pointer"} onClick={(e) => (!isRestricted || userAssigned) ? handleImportant() : {}}/> :
            <BsStar className={((!isRestricted || userAssigned) ? "" : "restricted-container ") + "align-vertical-middle fs-4 cursor_pointer"} onClick={(e) => (!isRestricted || userAssigned) ? handleImportant() : {}}/>}
          <div className="dropdown">
            <button className="btn dropdown-toggle lh-1 dropdown_actions_tile_item" aria-expanded="false" data-bs-toggle="dropdown" type="button" id={"dropdownContainerItemMenu_" + id} />
            <ul className="dropdown-menu" aria-labelledby={"dropdownContainerItemMenu_" + id}>
              <li className="dropdown-item">
                <a
                  className={"cursor_pointer float-end gap-5px d-flex"}
                  id={id}
                  onClick={(event) => handleEdit()}
                >

                  {isRestricted ?
                    <>
                    <MdPageview className="align-self-center cursor_pointer"/>
                    View
                    </>
                    :
                    <>
                    <VscEdit className="align-self-center cursor_pointer"/>
                    Edit
                    </>}
                </a>
              </li>
              {(!isRestricted || userAssigned) && <li className="dropdown-item">
                <a
                  className={`cursor_pointer float-end gap-5px d-flex`}
                  id={id}
                  onClick={(event) => handleComplete()}
                >
                  {isCompleted ? <TbRestore className="align-self-center cursor_pointer"/> :
                    <IoCheckmarkCircleOutline className="align-self-center cursor_pointer"/>}
                  {isCompleted ? "Mark as Incomplete" : "Mark as Completed"}
                </a>
              </li>}
              {!isRestricted &&
                <li className="dropdown-item">
                <a
                  className={"float-end cursor_pointer gap-5px d-flex color_red"}
                  id={id}
                  onClick={(event) => handleDelete()}
                >
                  <GoTrash className="align-self-center cursor_pointer"/>
                  Delete
                </a>
              </li>}

            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuickTask
