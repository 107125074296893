import { connect } from 'react-redux'
import TaskDashboardFilter from '../components/TaskDashboardFilter'
import {
  applyFilters,
  historySearch
} from '../actions/task_actions'

const mapStateToProps = (state) => ({
  assign_to_options: state.task.assign_to_options,
  tags_options: state.task.tags_options,
  filters: state.task.filters_list,
  user_id: state.task.user_id,
  taskId: state.task.task_id,
})

const mapDispatchToProps = (dispatch) => ({
  applyFilters: data => dispatch(applyFilters(data)),
  historySearch: data => dispatch(historySearch(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskDashboardFilter)