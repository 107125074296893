import moment from "moment";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import _ from "lodash";
import DatePickerWithTimezone from "./DatePickerWithTimezone";
function QuickTaskDueDate(props) {
  const {display_due_date, handleOpenClose, handleInputChange, open_date_picker,
    due_date_options, date_format, time_format, default_due_time, isCustomDate, timezone} = props

  const initialState = {
    due_date:  props.due_date,
    real_due_date: props.real_due_date
  }

  const [state, setState] = useState(initialState);

  const {due_date, real_due_date} = state
  const setDate = (date, real_date) => {
    setState({...state, real_due_date: real_date, due_date: date})
  }

  const close = () => {
    //reset state
    setState(initialState)

    handleOpenClose("open_date_picker", false)
  }

  const save = () => {
    handleInputChange("due_date", real_due_date)
  }

  useEffect(() => {
    document.querySelector('.react-datepicker__time-list-item--selected')?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }, [due_date, real_due_date]);

  useEffect(() => {
    setState(initialState)
  }, [props.due_date, props.real_due_date]);

  return (
    <div className="quick-task-form-datepicker">
      <div className="align-vertical-middle w-100 d-flex gap-5px"
           onClick={(e) => handleOpenClose("open_date_picker", !open_date_picker)}>
        <span className="align-vertical-middle">Due:</span>
        <i className="gsgicon-shop_task_datepicker fs-5 align-vertical-middle"/>
        <span className="align-vertical-middle"> {display_due_date} </span>
      </div>
      {open_date_picker &&
        <div className="quick-task-form-datepicker-main-body custom_high_z_index">
          <span className="flex-column d-flex align-vertical-middle w-100 text-start">Due</span>
          <div className="quick-task-form-datepicker-body custom_high_z_index">
            {/*<span*/}
            {/*  className="quick-task-form-datepicker-item flex-column d-flex align-vertical-middle w-100 text-start"></span>*/}
            {due_date_options.map((option, index) => (<>
                {/*{index == 3 && <span*/}
                {/*  className="quick-task-form-datepicker-item flex-column d-flex align-vertical-middle w-100 text-start"></span>}*/}
                <div
                  className={`quick-task-form-datepicker-item gap-5px d-flex w-100 text-start cursor-pointer ${option["value"] === due_date || (!due_date && option["value"] === "today") ? 'active' : ''}`}
                  key={index}
                  onClick={(e) => setDate(option["value"], option["real_value"])}
                >
                  <i className={`align-vertical-middle fs-5 ${option["icon"]}`}/>
                  <span className="align-vertical-middle">{option["label"]}</span>
                </div>
              </>
            ))}
          </div>
          <div className={`quick-task-form-datepicker-calendar ${due_date === "custom" ? "custom_date" : "not_custom_date"}`}>
            <DatePickerWithTimezone
                        name='due_datetime'
                        id='due_datetime'
                        showTimeSelect={true}
                        timeIntervals={15}
                        inline={true}
                        onChange={(e) => setDate(due_date, e)}
                        selected={real_due_date}
                        dateFormat={date_format}
                        timezone={timezone}
                        timeFormat={time_format}
                        autoComplete="off"
                        />
          </div>
          <div className="quick-task-form-datepicker-footer d-flex w-100">
            <span className="align-vertical-middle quick-task-form-datepicker-footer-item"
                  onClick={(e) => close()}>Cancel</span>
            <span className="align-vertical-middle quick-task-form-datepicker-footer-item"
                  onClick={(e) => save()}>Save</span>
          </div>
        </div>
      }
    </div>
  )
}

export default QuickTaskDueDate

