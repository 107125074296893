import { showErrorNotification, callAPI } from "shared/helpers";
import React from "react";

export const ActionTypes = {
  FETCH_EMAIL_CENTER_DATA_REQUEST: 'FETCH_EMAIL_CENTER_DATA_REQUEST',
  QUICK_SEARCH: 'QUICK_SEARCH',
  ENABLE_DISABLE_AUTOMATED_TEMPLATE: 'ENABLE_DISABLE_AUTOMATED_TEMPLATE',
  UPDATE_DEMO_REMINDER_TEMPLATE_DAYS: 'UPDATE_DEMO_REMINDER_TEMPLATE_DAYS',
  UPDATE_TEMPLATE_ATTRIBUTE: "UPDATE_TEMPLATE_ATTRIBUTE",
  CLONE_DEFAULT_TEMPLATE: 'CLONE_DEFAULT_TEMPLATE',
  DELETE_TEMPLATE: 'DELETE_TEMPLATE',
  RESET_TEMPLATES: 'RESET_TEMPLATES',
  COMMUNICATION_HISTORY_PAGINATE: 'COMMUNICATION_HISTORY_PAGINATE',
  PAGINATE_UNSENT_EMAILS: 'PAGINATE_UNSENT_EMAILS',
  CLOSE_CUSTOM_TEMPLATE_MODAL: 'CLOSE_CUSTOM_TEMPLATE_MODAL',
  OPEN_CUSTOM_TEMPLATE_MODAL: 'OPEN_CUSTOM_TEMPLATE_MODAL',
  OPEN_COMMUNICATION_TEMPLATE_MODAL: 'OPEN_COMMUNICATION_TEMPLATE_MODAL',
  UNSCHEDULE_EMAIL: 'UNSCHEDULE_EMAIL',
  DELETE_DRAFT_EMAIL: 'DELETE_DRAFT_EMAIL',
  CLOSE_COMMUNICATION_TEMPLATE_MODAL: 'CLOSE_COMMUNICATION_TEMPLATE_MODAL',
  PAUSE_SCHEDULED_EMAIL: 'PAUSE_SCHEDULED_EMAIL',
  RESUME_SCHEDULED_EMAIL: 'RESUME_SCHEDULED_EMAIL'
}

export const fetchEmailCenterData = () => (dispatch, getState) => {
	const state = getState();
	const dataURL = "/api/web/email_center";
	const params = { customer_id: state.dashboard.customer_id };

	callAPI(dataURL, "GET", params, new Headers({ Accept: "application/json" }))
		.then((response) => {
			dispatch({
				type: ActionTypes.FETCH_EMAIL_CENTER_DATA_REQUEST,
				data: response,
			});
		})
		.catch(() => {
			showErrorNotification("Error while fetching data!");
		});
};

export const changeQuickSearchValue = (value) => (dispatch, getState) => {
	dispatch({
		type: ActionTypes.QUICK_SEARCH,
		data: { quick_search_value: value },
	});
};

export const changeTemplateEnableValue =
	(template_details) => (dispatch, getState) => {
		const state = getState();
		const dataURL = "/api/web/email_center/update_notification";
		const params = {
			customer_id: state.dashboard.customer_id,
			category_type: template_details.template_key,
			is_enabled: template_details.enabled ? "true" : "false",
		};

		callAPI(
			dataURL,
			"POST",
			params,
			new Headers({ Accept: "application/json" })
		)
			.then((response) => {
				dispatch({
					type: ActionTypes.ENABLE_DISABLE_AUTOMATED_TEMPLATE,
					data: {
						template_category: template_details.template_category,
						template_key: template_details.template_key,
						enabled: template_details.enabled,
					},
				});
			})
			.catch(() => {
				showErrorNotification("Error while updating data!");
			});
	};

export const changeTemplateDemoRemindersDaysValue =
	(template_details) => (dispatch, getState) => {
		const state = getState();
		const dataURL = "/api/web/settings/update_demo_reminder_days";
		const params = {
			customer_id: state.dashboard.customer_id,
			type: template_details.template_key,
			days: template_details.days,
		};

		callAPI(
			dataURL,
			"POST",
			params,
			new Headers({ Accept: "application/json" })
		)
			.then((response) => {
				dispatch({
					type: ActionTypes.UPDATE_TEMPLATE_ATTRIBUTE,
					data: {
						template_category: template_details.template_category,
						template_key: template_details.template_key,
						days: template_details.days,
					},
				});
			})
			.catch(() => {
				showErrorNotification("Error while updating data!");
			});
	};

export const changeTemplateAttributeValue =
	(template_details) => (dispatch, getState) => {
		const state = getState();
		const dataURL = "/api/web/settings/update_template_attribute";

		const params = {
			customer_id: state.dashboard.customer_id,
			type: template_details.template_key,
			notification_channel: "email",
			value: template_details.value,
			attribute_type: template_details.attribute_type,
		};

		callAPI(
			dataURL,
			"POST",
			params,
			new Headers({ Accept: "application/json" })
		)
			.then((response) => {
				dispatch({
					type: ActionTypes.UPDATE_TEMPLATE_ATTRIBUTE,
					data: {
						template_category: template_details.template_category,
						template_key: template_details.template_key,
						value: template_details.value,
						attribute_type: template_details.attribute_type,
					},
				});
				window.gems.toast_message.init("Successfully updated!", "success")
			})
			.catch(() => {
				showErrorNotification("Error while updating data!");
			});
	};

export const cloneTemplate = (default_template_id, template_type) => (dispatch, getState) => {
  const state = getState()
  const dataURL = '/api/web/email_center/clone_default_template'
  const params = {
    customer_id: state.dashboard.customer_id,
    default_template_id: default_template_id,
    template_type: template_type,
  }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.CLONE_DEFAULT_TEMPLATE,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const deleteTemplate = (template_id) => (dispatch, getState) => {
	const state = getState();
	const dataURL = "/api/web/email_center/delete_template";
	const params = {
		customer_id: state.dashboard.customer_id,
		id: template_id,
	};

	callAPI(dataURL, "POST", params, new Headers({ Accept: "application/json" }))
		.then((response) => {
			dispatch({
				type: ActionTypes.DELETE_TEMPLATE,
				data: response,
			});
		})
		.catch(() => {
			showErrorNotification("Error while fetching data!");
		});
};

export const resetTemplates = () => (dispatch, getState) => {
	const state = getState();
	const dataURL = "/api/web/email_center/reset_templates";
	const params = {
		customer_id: state.dashboard.customer_id,
	};

	callAPI(dataURL, "POST", params, new Headers({ Accept: "application/json" }))
		.then((response) => {
			dispatch({
				type: ActionTypes.RESET_TEMPLATES,
				data: response,
			});
		})
		.catch(() => {
			showErrorNotification("Error while fetching data!");
		});
};

export const paginateCommunicationHistory = () => (dispatch, getState) => {
	const state = getState();
	const dataURL = "/api/web/email_center/paginate_communication_history";

	const params = {
		customer_id: state.dashboard.customer_id,
		page: state.sent_emails_history.page + 1,
	};

	callAPI(dataURL, "POST", params, new Headers({ Accept: "application/json" }))
		.then((response) => {
			dispatch({
				type: ActionTypes.COMMUNICATION_HISTORY_PAGINATE,
				data: response,
			});
		})
		.catch(() => {
			showErrorNotification("Error while fetching data!");
		});
};

export const paginateUnsentEmails = () => (dispatch, getState) => {
  const state = getState()
  const dataURL = '/api/web/email_center/paginate_unsent_emails'

  const params = {
    customer_id: state.dashboard.customer_id,
    page: state.scheduled_emails.page + 1
  }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.PAGINATE_UNSENT_EMAILS,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const openCustomTemplateModal = (data) => (dispatch, getState) => {
	const state = getState();
	var selected_template = null;

	if (data.template_type == "default")
		selected_template = state.custom_templates.default_email_templates.filter(
			(t) => t.id == data.template_id
		)[0];
	else
		selected_template = state.custom_templates.custom_email_templates.filter(
			(t) => t.id == data.template_id
		)[0];

	dispatch({
		type: ActionTypes.OPEN_CUSTOM_TEMPLATE_MODAL,
		data: {
			template: selected_template,
		},
	});
};

export const closeCustomTemplateModal = () => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CLOSE_CUSTOM_TEMPLATE_MODAL
  })
}

export const openCommunicationTemplateModal = (data, email_type) => (dispatch, getState) => {
  const state = getState()
  const selected_template = email_type == "sent_email" ? state.sent_emails_history.emails.filter((t) => t.id == data)[0] : state.scheduled_emails.emails.filter((t) => t.id == data)[0]

  dispatch({
    type: ActionTypes.OPEN_COMMUNICATION_TEMPLATE_MODAL,
    data: {
      template: selected_template
    },
  })
}

export const unscheduleEmail = (communication_history_id) => (dispatch, getState) => {
  const state = getState()
  const dataURL = '/api/web/email_center/unschedule_email'
  const params = {
    customer_id: state.dashboard.customer_id,
    communication_history_id: communication_history_id
  }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.UNSCHEDULE_EMAIL,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
  })
}

export const deleteDraftEmail = (communication_history_id) => (dispatch, getState) => {
  const state = getState()
  const dataURL = '/api/web/email_center/delete_draft_email'
  const params = {
    customer_id: state.dashboard.customer_id,
    communication_history_id: communication_history_id
  }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.DELETE_DRAFT_EMAIL,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
  })
}

export const pauseScheduledEmail = (communication_history_id) => (dispatch, getState) => {
  const state = getState()
  const dataURL = '/api/web/email_center/pause_scheduled_email'
  const params = {
    customer_id: state.dashboard.customer_id,
    communication_history_id: communication_history_id
  }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.PAUSE_SCHEDULED_EMAIL,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
  })
}

export const resumeScheduledEmail = (communication_history_id) => (dispatch, getState) => {
  const state = getState()
  const dataURL = '/api/web/email_center/resume_scheduled_email'
  const params = {
    customer_id: state.dashboard.customer_id,
    communication_history_id: communication_history_id
  }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.RESUME_SCHEDULED_EMAIL,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
  })
}

export const closeCommunicationTemplateModal = () => (dispatch, getState) => {
	dispatch({
		type: ActionTypes.CLOSE_COMMUNICATION_TEMPLATE_MODAL,
	});
};
