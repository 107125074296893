import moment from "moment";
import React, {useEffect, useState} from "react";
import {IoMdAddCircle} from "react-icons/io";
import { Form} from 'react-bootstrap';
import _ from "lodash";
function QuickTaskAssignTo(props) {
  const initialState = {
    assignees_list: props.assignees_list,
  }

  const [state, setState] = useState(initialState);
  const {assign_to_options, open_assign_to, handleOpenClose, handleInputChange} = props
  const {assignees_list} = state

  const Assign = (name, id) => {
    let current_list = [...assignees_list]

    let is_in = _.find(current_list, (cl) => cl.value === id )

    if(is_in)
      current_list = current_list.filter(item => item.value !== id)
    else
      current_list.push({value: id, label: name})

    setState({...state, assignees_list: current_list})
  }

  const close = () => {
    setState(initialState)
    handleOpenClose("open_assign_to", false)
  }

  const save = () => {
    let current_list = [...assignees_list]
    handleInputChange("assignees_list", current_list)
  }

  return (
    <div className="quick-task-form-assign-to">
      <div className="align-vertical-middle w-100 d-flex gap-5px" onClick={(e) => handleOpenClose("open_assign_to", true)}>
        <IoMdAddCircle className="fs-5 align-vertical-middle"/>
        <span className="align-vertical-middle">Assign To</span>
      </div>
      {open_assign_to &&
        <div className="quick-task-form-assign-to-body">
          <div className={"quick-task-form-assign-to-options-body w-100"}>
            {assign_to_options.map((option, index) => (option["options"] && <>
                {option["options"].map((op, i) => (
                  <div
                    className={"quick-task-form-assign-to-item d-flex w-100 text-start"}
                    key={i}
                  >
                    <Form.Check
                      value={op["value"]}
                      name={op["label"]}
                      defaultChecked={assignees_list.findIndex(a => a.value === op["value"]) > -1}
                      type='checkbox'
                      className="mb-1 mt-1 assing_to_checkbox"
                      onChange={(e) => Assign(e.target.name, e.target.value)}
                    />
                    <span
                      className={`${assignees_list.findIndex(a => a.value === op["value"]) > -1 ? 'bold' : ''} align-vertical-middle assing_to_checkbox_label`}>{op["label"]}</span>
                  </div>))
                }
                {(index+1) < assign_to_options.length &&
                  <span className="quick-task-form-assign-to-item flex-column d-flex align-vertical-middle w-100 text-start quick-task-form-assign-to-item-border"></span>}
              </>
            ))}
          </div>
          <div className="quick-task-form-assign-to-footer d-flex w-100">
            <span className="align-vertical-middle quick-task-form-assign-to-footer-item" onClick={(e) => close()}>Cancel</span>
            <span className="align-vertical-middle quick-task-form-assign-to-footer-item" onClick={(e) => save()}>Save</span>
          </div>
        </div>}
    </div>
  )
}

export default QuickTaskAssignTo

