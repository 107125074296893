import { connect } from 'react-redux'
import TaskDashboardBody from '../components/TaskDashboardBody'
import {
  createTask,
  editTask,
  newTask,
  setImportant,
  setCompleted,
  deleteTask,
  applyFilters
} from '../actions/task_actions'


const mapStateToProps = (state) => ({
    modal_open: state.task.modal_open,
    assign_to_options: state.task.assign_to_options,
    user_id: state.task.user_id,
    dateTimeFormat: state.task.date_time_info.dateTimeFormat,
    timezone: state.task.date_time_info.timezone,
    default_due_time: state.task.default_due_time,
    due_date_options: state.task.due_date_options,
    isRestricted: state.task.isRestricted,
    taskId: state.task.task_id,

    all_tasks: filterTasks(state.task.all_tasks, state.task.filters_list),
    important_tasks: filterTasks(state.task.all_tasks, state.task.filters_list, "important"),
    past_due_tasks: filterTasks(state.task.all_tasks, state.task.filters_list, "past_due"),
    today_tasks: filterTasks(state.task.all_tasks, state.task.filters_list, "today"),
    tomorrow_tasks: filterTasks(state.task.all_tasks, state.task.filters_list, "tomorrow"),
    this_week_tasks: filterTasks(state.task.all_tasks, state.task.filters_list, "this_week"),
    future_tasks: filterTasks(state.task.all_tasks, state.task.filters_list, "future"),
    recently_completed_tasks: filterTasks(state.task.all_tasks, state.task.filters_list, "recently_completed"),
    completed_tasks: filterTasks(state.task.all_tasks, state.task.filters_list, "completed"),
  }
)

const mapDispatchToProps = (dispatch) => ({
  newTask: (data) => dispatch(newTask(data)),
  createTask: (data, similar_task) => dispatch(createTask(data, similar_task)),
  editTask: (id) => dispatch(editTask(id)),
  deleteTask: (id) => dispatch(deleteTask(id)),
  setImportant: (data) => dispatch(setImportant(data)),
  setCompleted: (data) => dispatch(setCompleted(data)),
})

const filterTasks = (tasks, filters, position = null) => {
  return tasks
    .filter((task) => {
      const { quick_search, assignee, task_tag } = filters;
      const matchesSearch = quick_search
        ? task.name.toLowerCase().includes(quick_search.toLowerCase())
        : true;
      const matchesAssignee = assignee.label !== "All"
        ? task.assignees_ids.includes(assignee.value)
        : true;
      const matchesTag = task_tag.label !== "All"
        ? task.tags_ids.includes(task_tag.value)
        : true;

      return matchesSearch && matchesAssignee && matchesTag;
    })
    .filter((task) => (position ? task.position === position : true));
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskDashboardBody)