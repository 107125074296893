import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
function TaskDashboardHeader(props) {
    return (
      <div className="row custom_name border-bottom border-dark">
        <div className='col-4 p-0'>
          <h5 className="page-title ms-0">
            {" STAFF "}
            <span className="icon-record"/>
            {" SHOP TASKS "}
            <span className="icon-record"/>
            {" DASHBOARD "}
          </h5>
        </div>
        <div className='col d-flex justify-content-end'>
          <a
            className={"btn gems_custom_button gems_orange_button float-end"}
            href={props.add_dummy_url}
            type="button"
            data-method="get"
          >Add Dummy Tasks</a>
          <a
            className={"btn gems_custom_button float-end ms-1"}
            href={props.delete_dummy_url}
            type="button"
            data-method="get"
          >Delete All</a>
        </div>
      </div>

    )
}

export default TaskDashboardHeader
