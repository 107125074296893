import { ActionTypes } from '../actions/task_actions'
import _ from "lodash";

const {
  FETCH_TASKS_DATA_REQUEST,
  CREATE_TASK,
  NEW_TASK,
  EDIT_TASK,
  DELETE_TASK,
  UPDATE_TASK,
  CLOSE_MODAL,
  OPEN_MODAL,
  SWITCH_VIEW,
  OPEN_ASSIGN_MODAL,
  CLOSE_ASSIGN_MODAL,
  COMPLETE_TASK,
  APPLY_FILES_TO_TASK,
  HISTORY_SEARCH,
  APPLY_FILTERS} = ActionTypes

const initialState = {
  all_tasks: [],
  search_params: { finalized: false, canceled: false, deleted: false, status: '' },
  modal_open: false,
  modal_assign_open: false,
  view: "list",
  calendar_filter_date: "end_date",
  "quick_search_value": null,
  "condensed_tile_layout": false,
  filters_list: {searching: false, quick_search: "", assignee: {value: "all", label: "All"}, task_tag: {value: "all", label: "All"}},
  members_list: [],
  users_list: [],
  cadence_options: [],
  due_date_options: [],
  assign_to_options: [],
  notification_communication_data: [],
  tags_options: [],
  default_due_time: {h:'12', m:'00', nice:'12:00 PM'}
}

const item_initial_attrs =
                                  {"id": "",
                                    "name" : "",
                                    "due_date": "today",
                                    "description": "",
                                    "is_important": false,
                                    "recurring": false,
                                    "recurring_end_date": "",
                                    "recurring_cadence": null,
                                    "recurring_list_references": [],
                                    "internal_notes": "",
                                    "accepted_at": "",
                                    "completed_at": "",
                                    "tag_customer_type": "",
                                    "tag_member_id": "",
                                    "tag_guest_first_name": "",
                                    "tag_guest_last_name": "",
                                    "tag_guest_email": "",
                                    "tag_guest_phone": "",
                                    "tags_ids": [],
                                    "accepted_user_ids": [],
                                    "assigner_user_id": "",
                                    "completed_user_id": "",
                                    "customer_id": "",
                                    "created_at": "",
                                    "updated_at": "",
                                    "files": [],
                                    "activity_logs": [],
                                    "assignees_list": [],
                                  }
let index_id = null;

let all_tasks = [];

const task = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TASKS_DATA_REQUEST:
      return {
        ...state,
        all_tasks: action.data.all_tasks,
        search_params: action.data.search_params || initialState["search_params"],
        members_list: action.data.members_list,
        users_list: action.data.users_list,
        cadence_options: action.data.cadence_options,
        due_date_options: action.data.due_date_options,
        assign_to_options: action.data.assign_to_options,
        notification_communication_data: action.data.notification_communication_data,
        tags_options: action.data.tags_options,
        default_due_time: action.data.default_due_time,
        filters_list: action.data.filters_list,
      }
    case CREATE_TASK:
      return {
        ...state,
        ...{
          all_tasks: [...state.all_tasks, action.data.item],
          modal_open: action.data.similar_task ? true: false,
          item: action.data.similar_task,
        }

      }
    case UPDATE_TASK:
      all_tasks = [...state.all_tasks];
      index_id = _.findIndex(all_tasks, (task) => task.id === action.data.item.id)
      all_tasks[index_id] = action.data.item;

      return {
        ...state,
        ...{
          all_tasks: all_tasks,
          modal_open: false,
          item: null,
        }

      }
    case COMPLETE_TASK:
      all_tasks = [...state.all_tasks];
      // all_tasks = all_tasks.filter(task => task.id !== action.data.id);
      index_id = _.findIndex(all_tasks, (task) => task.id === action.data.item.id)
      all_tasks[index_id] = action.data.item;

      if(action.data.recurring_task)
        all_tasks.push(action.data.recurring_task)

      return {
        ...state,
        ...{
          all_tasks: all_tasks,
        }

      }
    case NEW_TASK:
      let item_params = item_initial_attrs;
      item_params["name"] = action.data["name"];
      item_params["due_date"] = action.data["due_date"] || item_params["due_date"];
      item_params["is_important"] = action.data["is_important"] || item_params["is_important"];
      item_params["assignees_list"] = action.data["assignees_list"] || item_params["assignees_list"];
      item_params["assigner_user_id"] = action.data["assigner_user_id"] || item_params["assigner_user_id"];

      return {
        ...state,
        modal_open: true,
        title: "New Task",
        btn_name: "Create",
        item: item_params,
      }
    case EDIT_TASK:
      let item = action.data.item
      item["recurring_list_references"] = JSON.parse(item["recurring_list_references"])

      return {
        ...state,
        modal_open: true,
        title: "Edit Task",
        btn_name: "Update",
        item: item,
        similar_task: null,
        task_id: null,
      }
    case DELETE_TASK:
      all_tasks = [...state.all_tasks];
      all_tasks = all_tasks.filter(task => task.id !== action.data.id);

      return {
        ...state,
        ...{
          all_tasks: all_tasks,
          modal_open: false,
          item: null,
        }

      }
    case OPEN_MODAL:
      return {
        ...state,
        modal_open: true
      }
    case CLOSE_MODAL:
      return {
        ...state,
        item: item_initial_attrs,
        modal_open: false
      }
    case SWITCH_VIEW:
      return {
        ...state,
        view: action.data
      }
    case OPEN_ASSIGN_MODAL:
      return {
        ...state,
        modal_assign_open: true,
      }
    case CLOSE_ASSIGN_MODAL:
      return {
        ...state,
        modal_assign_open: false
      }

    case APPLY_FILTERS:
      const filters = state.filters_list;
      return {
        ...state,
        filters_list: {...state.filters_list,
            [action.data.name]: action.data.value
        }
      }
    case HISTORY_SEARCH:
      all_tasks = [...state.all_tasks];

      all_tasks = all_tasks.filter(task => task.history === false);

      if(action.data.tasks && action.data.tasks.length > 0)
        action.data.tasks.forEach(task => {
          all_tasks.push(task)
        })

      return {
        ...state,
        ...{
          all_tasks: all_tasks,
          filters_list: {...state.filters_list,
            searching: false
          }
        }

      }
    case APPLY_FILES_TO_TASK:
      return {
        ...state,
        item: {
          ...state.item,
          files: [...state.item.files, action.data]
        }
      }
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default task