import { connect } from 'react-redux'
import {
  closeModal,
  createTask,
  updateTask,
  editTask,
  uploadFile,
  closeAssignModal,
  openAssignModal,
  openAssingnModal,
  deleteTask
} from '../actions/task_actions'
import TaskModal from '../components/TaskModal'
                     
const mapStateToProps = (state) => ({
  modal_open: state.task.modal_open,
  modal_assign_open: state.task.modal_assign_open,
  item: state.task.item ,
  title: state.task.isRestricted ? "View Task": state.task.title,
  members_list: state.task.members_list,
  users_list: state.task.users_list,
  btn_name: state.task.btn_name,
  customer_id: state.task.customer_id,
  similar_task: state.task.similar_task,
  dateTimeFormat: state.task.date_time_info.dateTimeFormat,
  timezone: state.task.date_time_info.timezone,
  cadence_options: state.task.cadence_options,
  assign_to_options: state.task.assign_to_options,
  user_id: state.task.user_id,
  default_due_time: state.task.default_due_time,
  due_date_options: state.task.due_date_options,
  tags_options: state.task.tags_options,
  notification_communication_data: state.task.notification_communication_data,
  isRestricted: state.task.isRestricted,
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal()),
  createTask: (data, similar_task) => dispatch(createTask(data, similar_task)),
  updateTask: data => dispatch(updateTask(data)),
  editTask: id => dispatch(editTask(id)),
  deleteTask: id => dispatch(deleteTask(id)),
  uploadFile: data => dispatch(uploadFile(data)),
  closeAssignModal: () => dispatch(closeAssignModal()),
  openAssignModal: () => dispatch(openAssignModal()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskModal)
